import React from "react"
import Layout from "../../components/Layout"
import bb from "../../../static/bb.jpg"
import roy from "../../../static/roy.jpeg"
import zhan from "../../../static/zhanel.jpeg"
import div from "../../../static/divya.jpeg"
import endri from "../../../static/endri.jpeg"
import yiming from "../../../static/yiming.jpg"

const aboutUs = () => {
  return (
    <Layout>
      <main className={"about-us-image w-100 flex-center-rw"}>
        <h1>About Us</h1>
      </main>
      <section className={"w-100 about-us-content flex-center-cl"}>
        <h2>Who We are</h2>
        <p>Aykunna is a creative and technology company specializing in designing & developing computer generated characters. We offer a unique experience-service by operating in three industries: music, entertainment, and fashion, all of which are supported by cutting-edge technology. We’ll design & characterize your own virtual character that fits to your brand’s style and spirit, then, we will bring it to life with the power of AI, VR and social media to maximize your audience engagement. We connect human senses, body, and mind.</p>
        <h2>Our Team</h2>
        <div className={"w-100 about-us-team"}>
          <div>
            <img src={endri} alt="aykunna ceo" />
            <h3>Endri</h3>
            <p>Founder & CEO</p>
          </div>
          <div>
            <img src={yiming} alt="aykunna ceo" />
            <h3>Yiming</h3>
            <p>CTO</p>
          </div>
          <div>
            <img src={div} alt="aykunna ceo" />
            <h3>Divya</h3>
            <p>Screenwriter</p>
          </div>
          <div>
            <img src={roy} alt="aykunna ceo" />
            <h3>Roy</h3>
            <p>Producer</p>
          </div>
          <div>
            <img src={zhan} alt="aykunna ceo" />
            <h3>Zhanelya</h3>
            <p>Vocalist</p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default aboutUs